
import React, { useEffect, useRef, useState } from 'react';
import Navbar from './Navbar';
import './App.css';   // Import your CSS file
import fbLogo from './fbLogo.png'
import instaLogo from './instaLogo.png'
import youtubeLogo from './youtubeLogo.png'
import emailLogo from './emailLogo.png'
import PhoneLogo from './PhoneLogo.png'
import playStore from './playStore.png'
import appStore from './appStore.png'
import homePageScreen from './homePageScreen.png'
import howToPlay from './howToPlay.png'
import unlockProfile1 from './unlockProfile1.png'
import unlockProfile2 from './unlockProfile2.png'
import chatToken from './chatToken.png'
import profileTicket from './profileTicket.png'

import { useParams } from 'react-router-dom';


const App = () => {

  const [SmallScreen, setSmallScreen] = useState(null)
  
  const sectionRefs = {
    home: useRef(null),
    aboutUs: useRef(null),
    section3: useRef(null),
    whatWeOffer: useRef(null),
    whyChooseUs: useRef(null),
    howToPlay: useRef(null),
    pricing: useRef(null),
    legal: useRef(null),
    section1:useRef(null),
  };


  useEffect(() => {


    // Redirect to the specified URL
   
    // Access the width of the screen




    // You can perform additional logic based on the screen width
    if (window.screen.width < 768) {
      setSmallScreen(true)
      // Do something for small screens
    } else {
      setSmallScreen(false)

      
      // Do something for large screens
    }
  });

  return (
    <div className="app-container">
      <Navbar />
      
      <main >
      {!SmallScreen ?
      <div id="home" ref={sectionRefs.home} style={{display:'flex', justifyContent:'space-around', alignItems:'center',width:'100%', backgroundColor:'#000'}}>
            
            
            
            <div>
            <br/>
        <br/>
          <img alt="Facebook" src={homePageScreen} className='homePageScreen' />
              </div>
      <div>
          <p className="mainIntro"><span style={{color:'#FF9F1A'}}>Invisible</span>Match <span style={{color:'#FF9F1A'}}>- Dating App</span></p>
          <p className="mainDesc">Keep your identity private & </p>
          <p className="mainDesc">play best of 3 to match.</p>
          <br/>
          <br/>

          <div className='storesSection'>

          <a href='https://play.google.com/store/apps/details?id=com.invisiblematch'><img alt="Playstore" src={playStore} className='storesLogo' /></a>
          <img alt="Appstore" src={appStore } className='storesLogo' />
          </div >
          <br/>
        <div className='storesSection' >
            <p className="mainIntroquote">Available </p>
          <p className="mainIntroquote">Coming soon  </p>
          </div>
          </div>
          </div>

          :
          
          <div id="home" ref={sectionRefs.home} style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center',width:'100%', backgroundColor:'#000'}}>
            <br/>
            <br/>
            
            
            
          <br/>
          <p className="mainIntro"><span style={{color:'#FF9F1A'}}>Invisible</span>Match <span style={{color:'#FF9F1A'}}>- Dating App</span></p>
        <p className="mainDesc">Keep your identity private & </p>
        <p className="mainDesc">play best of 3 to match.</p>
        <img alt="Facebook" src={homePageScreen} className='homePageScreen' />
    <div>
      
        <br/>

        <div className='storesSection'>

        <a href='https://play.google.com/store/apps/details?id=com.invisiblematch'><img alt="Playstore" src={playStore} className='storesLogo' /></a>
        <img alt="Appstore" src={appStore } className='storesLogo' />
        </div >
        <br/>
      <div className='storesSection' id="aboutUs" ref={sectionRefs.aboutUs}>
          <p className="mainIntroquote">Available </p>
        <p className="mainIntroquote"   >Coming soon  </p>
        </div>
        </div>
        </div>
        }
        <section  className="centered-section-main" id="aboutUs" ref={sectionRefs.aboutUs}>
          <div >
       
          
          {!SmallScreen ?
        <>
        </>
        :<>
        <br/>
        <br/>
        <br/>
        <br/>
        
        </>
        }
       </div>
        <section   className="startPadding"  >

          <h2  className="sectionTitle" >About us</h2>
          
          <p className="contentDesc">
          Have you ever heard the saying "Never judge a book by its cover"? If yes, why judge a person by the way they look? All the dating / match-making apps poised our way of looking at our matches. For these apps only looks matter the most, if it’s the same with you then <span style={{color:'#FF9F1A'}}>Invisible</span>match is not the right platform for you. <br/><br/>

At <span style={{color:'#FF9F1A'}}>Invisible</span>match we prioritize <span style={{color:'#FF9F1A'}}>“understanding”</span> your potential match rather than how they look. As a platform, <span style={{color:'#FF9F1A'}}>Invisible</span>matach provides<br/> non-judgmental matching. <br/><br/>

You have to decide either to judge a book by its cover or read the<br/> book & fall in love with it.   </p>
        </section>

        <section id="howToPlay"  >
          
        <br/>
        <br/>
        {!SmallScreen ?
        <div>
          <h2 className="sectionTitle">How to play?</h2>
      <div style={{display:'flex', justifyContent:'space-around', alignItems:'center',width:'100%', backgroundColor:'#000'}}>
      <div>
          <img alt="Facebook" src={howToPlay} className='homePageScreen' />
              </div>
              <div>
          <p className="contentDesc">

<span style={{textDecoration:'underline'}}>Set-up 3 questions you would <br/> like to ask your potential partner.</span>
<br/><br/>
<span style={{color:'#FF9F1A'}}>Invisible</span>Match allows you to create 3 <br/>questions that you could like ask your <br/> potential match. You can ask about his/her life <br/>quality or is he/she will be ok with yours. That<br/> could be morals, lifestyle, approach to life,<br/> fun, food, entertainments etc..
<br/><br/>

Need list of questions you could ask?
          </p>
<a href='https://docs.google.com/spreadsheets/d/1tLXykp74JADC8T27Br3vX-yW1U_r52milQpe1aAXWBE/edit?usp=sharing'><button className="buttonStyle"  >Tap here</button></a>
          </div>
          </div>

<br/>
</div>
          :
          <div >
            <br/>
            <br/>

          <h2 className="sectionTitle">How to play?</h2>
      <div style={{display:'flex', justifyContent:'center',flexDirection:'column', alignItems:'center',width:'100%', backgroundColor:'#000'}}>
      <div>
          <img alt="Facebook" src={howToPlay} className='homePageScreen' />
              </div>
              <div>
          <p className="contentDesc">

<span style={{textDecoration:'underline'}}>Set-up 3 questions you would <br/> like to ask your potential partner.</span>
<br/><br/>
<span style={{color:'#FF9F1A'}}>Invisible</span>Match allows you to create 3 <br/>questions that you could like ask your <br/> potential match. You can ask about his/her life <br/>quality or is he/she will be ok with yours. That<br/> could be morals, lifestyle, approach to life,<br/> fun, food, entertainments etc..
<br/><br/>

Need list of questions you could ask?
          </p>
<a href='https://docs.google.com/spreadsheets/d/1tLXykp74JADC8T27Br3vX-yW1U_r52milQpe1aAXWBE/edit?usp=sharing'><button className="buttonStyle"  >Tap here</button></a>
          </div>
          </div>

<br/>
</div>    
          
          }
        </section>

        {!SmallScreen ?
        <>
        <br/>
        <br/></>
        :<></>
        }
        <section id="whatWeOffer"  ref={sectionRefs.whatWeOffer} >
        {!SmallScreen?
        <>
        <br/>
        </>:
        <>
        <br/>
        <br/>
        <br/>
        </>
        }
          <h3 className='unlockProfile'>Unlock profile & chat by playing best of 3 set by your potential match</h3>
          <br></br>

        {!SmallScreen ?
      <div>
      <div style={{display:'flex', justifyContent:'space-around', alignItems:'center',width:'100%', backgroundColor:'#000'}}>
      <div>
          <img alt="Facebook" src={unlockProfile1} className='homePageScreen' />
              </div> <div>
          <img alt="Facebook" src={unlockProfile2} className='homePageScreen' />
              </div>
          </div>
            <br/>
            </div>
        :
        <div>
        <div style={{display:'flex', justifyContent:'center',flexDirection:'column', alignItems:'center',width:'100%', backgroundColor:'#000'}}>
        <br/>
        <br/>
        
        <div>

            <img alt="Facebook" src={unlockProfile1} className='homePageScreen' />
                </div> <div>
            <img alt="Facebook" src={unlockProfile2} className='homePageScreen' />
                </div>
            </div>
              <br/>
              </div>
        }
        </section>

        {!SmallScreen ?
        <>
        <br/>
        <br/></>
        :<></>
        }
        <section id="whyChooseUs" ref={sectionRefs.whyChooseUs} className="centered-section-choose">
        {!SmallScreen?
        <>
        <br/>
        </>:
        <>
        <br/>
        <br/>
        <br/>
        </>
        }

          <h2 className="sectionTitle">Why choose us?</h2>
          <div className="contentDesc">
            <p>
            <span style={{color:'#FF9F1A'}}>Invisible</span>match is the world’s first dating app that doesn’t collect your<br/> contact number or email address to use the service. We want to keep<br/> the app as private as we can so that you never get spammed by us.<br/><br/>

Your photo is blurred while displaying it to your potential match. If your<br/> potential match wins the best of 3 set by you, we then display your<br/> photo for them to take a look & unlock the chat option for them to send <br/>you a message. You can block them at any given time if you don’t want<br/> to have a conversation with them.       </p>

       
          
      
            {/* </div> */}  <br/>
            <br/>
        <br/>
    {/* <span ></span> */}
    </div>

        </section>



        <section id="pricing" ref={sectionRefs.whyChooseUs} className="centered-section-choose">
        {!SmallScreen?
        <>
        <br/>
        </>:
        <>
        <br/>
        <br/>
        <br/>
        </>
        }

          <h2 className="sectionTitle">Pricing</h2>
          <div style={{display:'flex',alignItems:'flex-start',justifyContent:'flex-start',width:'100%',
  padding:'10px 9.6vw 0px 9.6vw'
}} >
            <img alt="Facebook" src={profileTicket} className='profileTicketIcon' />

            </div>
          <div className="contentDesc">
            

            <p>

            In order to avoid fake & troll users we request you to send us your real time<br/> selfie. Make sure your face is clearly visible. Your photo won’t be visible to<br/> other app users. For this verification we charge Rs.100. Your profile would <br/>be verified within 15 minutes of payment. Rs.100 will be added back to your<br/> account wallet for you to match & chat. This practice is done to keep the<br/> platform clean for people who are seriously finding a partner.<br/><br/>  
 
            You can buy profile tickets and chat tokens from the shop anytime.
            <br/>
            <br/>
            <div style={{display:'flex', justifyContent:'space-around', alignItems:'center', backgroundColor:'#000'}}>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
          <img alt="Facebook" src={profileTicket} className='profileTicketIcon' />
          <p className="ticketsTitle">Profile Ticket</p>
              </div> 
              <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>

          <img alt="Facebook" src={chatToken} className='profileTicketIcon' />
          <p className="ticketsTitle">Chat token</p>

              </div>
          </div>
 </p>

       
          
      
            {/* </div> */}  <br/>
            <br/>
        <br/>
    {/* <span ></span> */}
    </div>

        </section>

        {!SmallScreen ?
        <>
        <br/></>
        :<></>
        }

        <section id="legal" ref={sectionRefs.contactUs} className="centered-section-contact">

        {!SmallScreen?
        <>
        <br/>
        </>:
        <>
        <br/>
        <br/>
        <br/>
        </>
        }
          <h2 className="sectionTitle">Legal</h2>
          <div className="contentDescContact">
            
          <div 
            className="contactDiv"
          >
            <div className="contactSec">
              <p>Quick Links</p>
                <ul>
                  <li><a href='/privacy'><p>Privacy Policy</p> </a></li>
                  <li><a href='/terms'><p>Terms of Use</p> </a></li>
                  <li><a href='/shipping'><p>Shipping & Delivery Policy</p> </a></li>
                  <li><a href='/cancel'><p>Cancellation & Refund Policies</p> </a></li>
                </ul>
                <p>Follow us at</p>
                <div>
    <a href='https://www.facebook.com/profile.php?id=61552479365299'>
                <img
          className="imageLogoLink"

            alt="Facebook"
            src={fbLogo}


          />
          </a>
    <a href='https://www.instagram.com/invisiblematch?igsh=MXF1cXNwMzl1a2lxMw=='>

          <img 
          
          className="imageLogoLink"
            alt="Instagram"
            src={instaLogo}
          />
          </a>
          <a
          href='https://www.youtube.com/channel/UCHQLO6vpm3ooApx5x8LVIyQ'
          >
           <img
                     className="imageLogoLink"

            alt="YouTube"
            src={youtubeLogo}
          />
          </a>
                </div>

            </div>
            <div className="contactSec">
           <p> Reach us at</p>
           <div style={{display:'flex',alignContent:'center',}}>
           <img
           style={{marginRight:'10px'}}
            alt=""
            height="42vh"
            src={emailLogo}
          />  
          <p>
support@stuffenzy.com
          </p>
          </div>
          <br/>
          <div style={{display:'flex',alignContent:'center',}}>
          <img
            height="42vh"
            style={{marginRight:'10px'}}
          
            alt=""
            src={PhoneLogo}
          />  
          <p>+91 93214 22611</p>
      </div>
      <br/>

            <p>
            STUFFENZY LLP, 46 LF BLDG 5B CTS, <br></br>
1A12B WI LINKING RD,<br></br>
 OPP OSHIWARA DEPOT, GOREGAON WEST,<br></br>
  MUMBAI-400104,
 MAHARASHTRA INDIA<br></br>
            </p>
            </div>
          </div>
          
            </div>

            <br></br>
            <br></br>
            <br></br>
   <footer className="footer"> © Stuffenzy LLP, 2024. All rights reserved. </footer>
    
        </section>
        </section >

        {/* Add more sections or components as needed */}
      </main>
    </div>
  );
};

export default App;
