import React, { Component } from 'react'
export class CancelPage extends Component {
  render() {
    return (
        <div
      
        style={{padding:40}}    
        >
  
  
  <h1><b>Purchases, Cancellation, Returns and Refunds</b></h1> 
  
  <p>Since Invisible Match does not sell physical goods there is no cancellation or return. Invisible Match users can make a payment to purchase digital goods which can be used for soliciting a connection with other Invisible Match users. Digitals goods include chat tokens and profile tickets. New digital goods may be introduced and older digital goods may be modified or removed from time to time. You will not post, or transmit to other users, any defamatory, inaccurate, abusive, obscene, profane, offensive, sexually oriented, threatening, harassing, racially offensive, or illegal material, or any material that infringes or violates another party's rights.</p>
<p>When you make an in-app purchase, you will be prompted to enter details for your account with your Software Store ("your IAP Account"), and your IAP Account will be charged for the in app purchase in accordance with the terms disclosed to you at the time of purchase as well as the general terms for in-app purchases that apply to your IAP Account. Some Software Stores may charge you sales tax, depending on where you live. </p>
<p>In general, payments once made, are not refunded under any circumstances. In other words, you do not have the option of cancelling or getting a refund for any payment.</p>

<p>Any payment related queries regarding payments made from iOS devices using your Apple ID must be sent to Apple and not to Invisible Match. To request a refund, please visit https://getsupport.apple.com. If you made the payment using your Google Play Store, please contact the Invisible Match customer support by writing with your order details and invoice from the Google Play Store. If you made a payment through the Website or through any other payment gateway, please contact the Invisible Match customer support by writing with your order details and invoice at support@stuffenzy.com</p>
  
   
  <br/>
        </div>
    )
  }
}

export default CancelPage