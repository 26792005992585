// Navbar.js

import React, { useEffect, useState } from 'react';
import { Navbar, Container, Nav ,NavbarToggle} from 'react-bootstrap';
// import { Navbar, Nav, NavbarBrand,NavbarToggle } from 'react-bootstrap';
// import {
//   Navbar,
//   Nav,
//   NavItem,
//   NavDropdown,
//   DropdownMenu,
//   NavbarBrand,
//   NavLink,
//   Dropdown,
// } from 'react-bootstrap';
// import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import './Navbar.css'
import Imagelogo from './imageLogo.png'
const CustomNavbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [SmallScreen, setSmallScreen] = useState(null)

  const handleDropdownToggle = (isDropdownOpen) => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    // Access the width of the screen
    const screenWidth = window.screen.width;
    console.log('Screen Width:', screenWidth);

    // You can perform additional logic based on the screen width
    if (screenWidth < 768) {
      setSmallScreen(true)
      // Do something for small screens
    } else {
      setSmallScreen(false)

      console.log('Large screen!');
      // Do something for large screens
    }
  }, []);
  return (
    <Navbar expanded={isDropdownOpen} style={{backgroundColor:'#fff'}} className='navBar' expand="lg" sticky="top" >
      <Container className="navContainer">
         
         <a href='#home'> <img
            alt=""
            src={Imagelogo}
            
            className="d-inline-block align-top imageLogo"
          />
          </a>
        <div>

      
               <Navbar.Toggle onClick={()=>handleDropdownToggle(isDropdownOpen)}  aria-expanded="true"  data-target="#basic-navbar-nav"  />
        <Navbar.Collapse  id="basic-navbar-nav">
        <Nav  id="basic-navbar-nav" >
          <Nav.Link  className="linkFontColor" onClick={()=>handleDropdownToggle(isDropdownOpen)} href="#aboutUs">About us</Nav.Link>
          <Nav.Link className="linkFontColor " onClick={()=>handleDropdownToggle(isDropdownOpen)} href="#howToPlay">How to play?</Nav.Link>
          <Nav.Link className="linkFontColor " onClick={()=>handleDropdownToggle(isDropdownOpen)} href="#whyChooseUs">Why choose us? </Nav.Link>
          <Nav.Link className="linkFontColor " onClick={()=>handleDropdownToggle(isDropdownOpen)} href='#pricing'>Pricing</Nav.Link>
          <Nav.Link className="linkFontColor " onClick={()=>handleDropdownToggle(isDropdownOpen)} href="#legal">Legal</Nav.Link>
        </Nav>
        </Navbar.Collapse>
   
        </div>
      </Container>
    </Navbar>
    
  //   <Navbar expand="sm" bg="dark" variant="dark">
  //   <NavbarBrand href="#">Invisible Match</NavbarBrand>
  //   <Nav className="mr-auto">
  //     <NavItem>
  //       <NavLink href="#">Home</NavLink>
  //     </NavItem>
  //     <NavItem>
  //       <NavLink href="#">How to use?</NavLink>
  //     </NavItem>
  //     <NavDropdown
  //       title="More"
  //       show={isDropdownOpen}
  //       onToggle={()=>handleDropdownToggle(isDropdownOpen)}
  //       disabled={isDropdownOpen}
  //     >
  //       <DropdownMenu>
  //         <Dropdown.Item href="#">Your Privacy</Dropdown.Item>
  //         <Dropdown.Item href="#">Pricing</Dropdown.Item>
  //         <Dropdown.Item href="#">Legal</Dropdown.Item>
  //       </DropdownMenu>
  //     </NavDropdown>
  //   </Nav>
  // </Navbar>
  //  <Navbar bg="light" expand="lg">
  //     <Navbar.Brand href="#home">Your Logo</Navbar.Brand>
  //     <Navbar.Toggle aria-controls="basic-navbar-nav" />
  //     <Navbar.Collapse id="basic-navbar-nav">
  //       {/* Nav items for desktop view */}
  //       <Nav className="mr-auto ">
  //         <Nav.Link href="#home">Item 1</Nav.Link>
  //         <Nav.Link href="#link">Item 2</Nav.Link>
  //         <Nav.Link href="#link">Item 3</Nav.Link>
  //         <Nav.Link href="#link">Item 4</Nav.Link>
  //         <Nav.Link href="#link">Item 5</Nav.Link>
  //       </Nav>

  //       {/* Nav items for mobile view */}
  //       {/* <Nav className="ml-auto d-lg-none">
          
  //         <NavDropdown title="Menu" id="basic-nav-dropdown">
  //           <NavDropdown.Item href="#home">Item 1</NavDropdown.Item>
  //           <NavDropdown.Item href="#link">Item 2</NavDropdown.Item>
  //           <NavDropdown.Item href="#link">Item 3</NavDropdown.Item>
  //           <NavDropdown.Item href="#link">Item 4</NavDropdown.Item>
  //           <NavDropdown.Item href="#link">Item 5</NavDropdown.Item>
  //         </NavDropdown>
  //       </Nav> */}
  //     </Navbar.Collapse>
  //   </Navbar>
  );
};

export default CustomNavbar;



// Navbar.js

// import React from 'react';
// import { Navbar, Container, Nav } from 'react-bootstrap';

// const CustomNavbar = () => {
//   return (
//     <Navbar bg="dark" variant="dark" sticky="top">
//       <Container>
//         <Navbar.Brand href="#">
//           <img
//             alt=""
//             src="your-icon-image-url"
//             width="30"
//             height="30"
//             className="d-inline-block align-top"
//           />
//           {' Your Brand'}
//         </Navbar.Brand>
//         <Nav className="ml-auto">
//           {[1, 2, 3, 4, 5].map((index) => (
//             <Nav.Link key={index} href="#">
//               Link {index}
//             </Nav.Link>
//           ))}
//         </Nav>
//       </Container>
//     </Navbar>
//   );
// };

// export default CustomNavbar;
