import React, { Component } from 'react'

export class Terms extends Component {
  render() {
    return (
      <div
      style={{padding:40}}
      >


<h1 style={{textDecoration:'underline',}}>
  <b>Terms of use</b></h1> 

  <p>These terms of use apply to your use of the website of Invisible Match (www.Invisiblematch.com - hereafter the "Website") and Invisible Match mobile application (iOS application / Android application - hereafter the "App"), provided by Stuffenzy LLP (hereafter "we" or "us" or "our" or "Invisible Match"). Please go through them carefully. If you object to anything in the terms of use or our privacy policy, please do not use the website or the app or any other service we offer.</p>
  <h1>Acceptance of Terms of Use Agreement</h1>
  <p>We reserve the right, at our discretion, to make changes to the terms of use and privacy policy at any time. We may do this for a variety of reasons including to reflect changes in new features, changes in business practices or requirements of the law. The most updated version is available on our app and website with the date of last revision. Please check the terms of use and privacy policy periodically for changes as all modifications are effective upon publication. Continued use of the Invisible Match app and website after revision indicates the user's acceptance of the terms of use. We will notify Invisible Match users when modifications are made to the terms of use and/or privacy policy via our App.</p>
  <h1>Eligibility</h1>
  <p>Invisible Match is a social platform with the purpose to connect like-minded people around the globe to find love. By creating an account on Invisible Match you hereby warrant the following;<br/><br/>
  
  - you are atleast 18 years of age<br/><br/>
  
  - you are currently unmarried<br/><br/>
  
  - you can form a binding contract with Invisible Match<br/><br/>
  
  - you are not barred from using the Service under the United States laws or any other jurisdiction worldwide<br/><br/>
  
  - you do not have any unspent convictions, or are subject to any court order, relating to assault, violence, sexual misconduct or harassment<br/><br/>
  
  - you will not use our Website or App if you have been removed from Invisible Match<br/><br/>
  
  - you do not appear on the U.S. Treasury Department's list of Specially Designated Nationals or face any other similar prohibition<br/><br/>
  
  - you will comply with this agreement and all applicable local, state, national and international laws, rules and regulations<br/><br/>
  
  - we are entitled to remove your accounts with Invisible Match permanently if any of the above conditions are not met<br/><br/>
  
  - you are not a citizen of any country that is a part of the EU</p><br/>

  
  
  
  
 <h1> Governing Law</h1>
 <p> These terms of use and privacy policy shall be governed in all respects by the laws of India and you agree to submit to the exclusive jurisdiction of the courts in Mumbai, Maharashtra.</p>
  
  
  <h1>Registration and Your Account</h1>
  <p>- If you want to use the Website or App, you first need to register. In order to register, you need to authenticate yourself using realtime selfie verification . After registering, you can create a personal account ("Your Account"). When creating Your Account, we will need your pictures. You are further invited to provide us with more information about yourself including but not limited to your interests, story, pictures, philosophy, work and an icebreaker, apart from basic information about yourself (such as your name, gender, date of birth, height, relationship status, religion, ethnicity, city of residence). You email will serve as your identifier while contacting support.<br/><br/><br/>
  
  
  - The registration and creation of Your Account qualifies you as a user. Users can be suspended by us if we receive several complaints from other users.<br/><br/><br/>
  
  
  - By registering for Invisible Match, you grant us permission to send you in app notifications about your profile related activities.</p>
  
  
 <h1> Community Guidelines</h1>
  <p>You agree to Invisible Match using emails and other means to notify and engage you with the service. Invisible Match users are expected to conduct themselves with a high level of dignity and professionalism. Unacceptable behaviour can lead to the termination of their membership. The below etiquette is expected of Invisible Match users:<br/><br/>
  
  - You will not post, or transmit to other users, any defamatory, inaccurate, abusive, obscene, profane, offensive, sexually oriented, threatening, harassing, racially offensive, or illegal material, or any material that infringes or violates another party's rights.<br/><br/>
  
  - You will not use a robot, spider, site search/retrieval application, or other manual or automatic device or process to retrieve, index, data mine, or in any way reproduce, reverse engineer or bypass the navigational structure or presentation of the service or its contents.<br/><br/>
  
  - You will not use the Invisible Match app for soliciting your own business activities or for commercial purposes.<br/><br/>

  - You will not provide inaccurate, misleading or false information to Invisible Match or to any other user.<br/><br/>
  
  - You will not publish links to other websites.<br/><br/>
  
  - You should be using your discretion while sharing personal information with other users on Invisible Match. Invisible Match Members are expected to exercise simple precautions for their privacy and safety. You may not publish any personal contact information on Your Account, such as your complete name, address, email address, telephone number, instant messaging contact details or social media website URLs or usernames. In addition, for your safety, you may not include personal contact information in messages to users who have not previously contacted you.<br/><br/>
  
  - Your account will be terminated if you are found to misuse the written spaces on Invisible Match.<br/><br/>
  
  - Your photos will be reviewed before being published on Invisible Match.<br/><br/>
  
  - You will not upload viruses or other malicious code or compromise the security of the services offered by Invisible Match in any way.<br/><br/>
  
  - You will not post content that is vulgar, defamatory, controversial, violative of copyrights and trademark rights, violative of any law that the publishing of such content may be subject to.<br/><br/>
  
  - You will use in a lawful, responsible and respectful manner, any information provided by another Invisible Match user.<br/><br/>
  
  - You will not use the service to disrupt public order.<br/><br/>
  
  - You will not infringe upon the normal running of Invisible Match or its infrastructure in any way.<br/><br/>
  
  - You will immediately cease contacting any user who asks you to stop contacting them.<br/><br/>
  
  - You will not post, copy, modify, disclose or distribute via our Website or App any confidential information; or any other material which is subject to our or a third party's (intellectual property) rights, without first obtaining our or the relevant third party's prior written consent.<br/><br/>
  
  - You agree to compensate us for any claim or damages (including any legal fees in relation to such claim or damages) demanded by a third party in respect of any matter relating to or arising from any breach or suspected breach by you of these terms of use or the rights of a third party. We reserve the right to issue warnings, suspend access to Your Account or terminate Your Account, if we reasonably consider that you are in breach of these terms of use.<br/><br/>
  
  Invisible Match provides assistance and guidance through its customer care team. When communicating with us, you agree to not be abusive, obscene, profane, offensive, sexist, threatening, harassing, racially offensive, or indulge in any other inappropriate behaviour. If we feel that your behavior towards our customer care team or any other employee is threatening or offensive, we reserve the right to immediately terminate your account without any warning.<br/><br/>
  
  Invisible Match reserves the right to investigate and/or terminate your account without any refund or reimbursement if we feel you have misled Invisible Match or its users, or misused the Service, or behaved in a way that Invisible Match regards as inappropriate or unlawful, including actions or communications that occur off the service involving users you met through Invisible Match.</p>


  <h1>Safety</h1>
  <p>The company currently does not conduct any criminal and/or other formal background checks of all its users and also does not attempt to verify the statements of its users. You are solely responsible for taking all appropriate safety precautions in connection with the use of the website and app and contacting other users. You accept that there are risks interacting online or offline with other users, including dating and meeting other users. We do not guarantee or verify the accuracy of information provided to you by other users. Invisible Match makes every effort to keep the information made available on the website and app accurate and up to date, but we do not guarantee that the information is accurate, complete or current. No rights can be derived from it. Any reliance on the provided information is at your own risk. We do not warrant that the website or app will be available uninterrupted and in a fully operating condition. All content and services on the website or app are provided on an "as is" and "as available" basis. Any decisions or actions taken by you on the basis of the information provided on or via the website and app are at your sole discretion and risk.<br/><br/>
  You will not post, or transmit to other users, any defamatory, inaccurate, abusive, obscene, profane, offensive, sexually oriented, threatening, harassing, racially offensive, or illegal material, or any material that infringes or violates another party's rights.<br/><br/>
  
  Invisible Match shall not collect data from minors. Should a Member lie about his/her date of birth, and particularly if he/she fraudulently claims to be over 18 years of age, the parents of the minor in question should inform Invisible Match of this by sending an e-mail requesting the data to be deleted. Invisible Match commits to deleting all data on the minor in question as soon as possible.</p>
  
  <h1>License</h1>
  <p>The rights granted by Invisible Match to the user are restricted to private and personal use. As a user of Invisible Match, you grant Invisible Match a free-of-charge, non-exclusive, international and permanent license for the use, reproduction, representation, modification and translation of any basic intellectual property-related component (text, emojis, photos, videos etc.) that it may provide through the App for the non-exclusive purpose of communicating with other users on Invisible Match. Invisible Match can use such content in any format on the Website, App, ads, other shielded areas accessible by other users and for other internal purposes.<br/><br/>
  
  Invisible Match reserves the right at any time to modify or discontinue, temporarily or permanently, the service (or any part thereof) with or without notice. You agree that Invisible Match shall not be liable to you or to any third party for any modification, suspension or discontinuance of the service.</p>
  
  <h1>Deletion of Account</h1>
  <p>A user of Invisible Match may decide at any time and without notice to delete their account. If this user wishes to use Invisible Match again, they will be required to delete the app.<br/><br/>

  If a user deletes their account:<br/><br/>
  
  - Their profile will be removed from the list of profiles on Invisible Match<br/>
  - The user cannot reactivate their deleted profile<br/><br/>
  
  Invisible Match reserves the right to terminate your membership, to suspend a profile or to disable access with respect to a breach of any of the terms with or without notice. Under any circumstances purchases made on Invisible Match will not be refundable.</p>
  
  <h1>Copyright Infringement Claims</h1>
  <p>You may not post, distribute, or reproduce in any way any copyrighted material, trademarks, or other proprietary information without obtaining the prior written consent of the owner of such proprietary rights. Without limiting the foregoing, if you believe that your work has been copied and posted on the Service in a way that constitutes copyright infringement, please provide us with the following information:<br/><br/>
  
  - An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest<br/><br/>

  - A description of the copyrighted work that you claim has been infringed<br/><br/>

  - A description of where the material that you claim is infringing is located on the app<br/><br/>

  - Your full name, address, telephone number, and email address<br/><br/>

  - A written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law<br/><br/>

  - A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf</p>
  <h1>External Links and Services</h1>
 <p> The service may provide, or third parties may provide, links to other world wide web sites or resources. Because Invisible Match has no control over such sites and resources, you acknowledge and agree that Invisible Match is not responsible for the availability of such external sites or resources, is not responsible or liable for any content, advertising, products or other materials on or available from such sites or resources. You further acknowledge and agree that Invisible Match shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of, or reliance upon, any such content, goods or services available on or through any such site or resource. Please go through the terms and privacy policies of such sites carefully as you would be bound by those during such interactions.</p>
  <h1>Limitation of Liability and Disputes</h1>
  <p>- You are solely responsible for your interactions with other Invisible Match users. Invisible Match reserves the right to, but is not obliged to, monitor the resolution of any disputes arising between Invisible Match users. We are not obliged to become involved in any domestic or private disputes between users and do not provide any arbitration or settlement service. Invisible Match also reserves the right to take action, as deemed appropriate against errant users.<br/><br/>
  
  - In no event shall Invisible Match, its affiliates, employees, agents, licensors or partners be liable for any damages whatsoever, whether direct, indirect, general, special, compensatory, consequential, and/or incidental, arising out of or relating to the conduct of you or anyone else in connection with the use of the service, including without limitation, bodily injury, emotional distress, loss of use, loss of data, loss caused by a computer or electronic virus, loss of income or profit, loss of or damage to property, wasted management or office time, breach of contract or claims of third parties or other losses of any kind or character, even if Invisible Match has been advised of the possibility of such damages or losses, arising out of or in connection with the use of Invisible Match and/or any other damages resulting from communications or meetings with other users of this service or persons you meet through this service.<br/><br/>
  
  - If you choose to invite someone to join our Website or App, you may select a person directly from your contacts list on your device and send a text, email or message from your personal account. You understand and agree that you are responsible for any charges made against communications sent from your device.<br/><br/>
  
  - We will provide Invisible Match service to you with reasonable skill and care. We do not make any warranties or representations (neither express nor implied) with respect to the Website, App or the associated services.<br/><br/>
  
  - You hereby expressly agree not to hold Invisible Match liable for any instruction, advice, or services delivered through our website or app. Invisible Match expressly disclaims any liability whatsoever for any damage, suits, claims, and/or controversies that arise or relate in any way to our website or app.<br/><br/>
  
  - You expressly understand and agree that Invisible Match will not be liable for direct, indirect, incidental, special, consequential, or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses, resulting from: a) the use or inability to use the website or app; b) the cost of procurement of substitute goods and services resulting from any goods, data, information or services obtained or messages received or transactions entered into through, from, or as a result of the website or app; c) unauthorized access to or alteration of your transmissions or data; d) statements or conduct of any user or third party on the website or app; e) your reliance on content data made available by us; or f) any other matter relating to the website or app<br/><br/>
  
  - Nothing in these terms of use shall exclude or limit our liability for our fraudulent misrepresentation or for death or personal injury resulting from our negligence or the negligence of our employees or agents.<br/><br/>
  
  - To the fullest extent possible by law, Invisible Match's maximum liability arising out of or in connection with the website or app or your use of our content, regardless of the cause of action (whether in contract, tort, breach of warranty, or otherwise), will not exceed greater than the fees you have paid to make purchases on Invisible Match.</p>
  
  <h1>Indemnity</h1>
  <p>To the full extent permitted under Indian laws, you agree to defend, indemnify and hold harmless Invisible Match, it's officers, directors and employees from and against all liabilities, demands, claims, damages, complaints, losses, costs, and expenses, including attorney's fees, due to, arising out of, or relating in any way to your access to or use of the services, your content, or your breach of this agreement.</p>
  
  <h1>Disclaimers</h1>
  <p>Invisible Match does not represent or warrant that (a) any matches or profiles presented will be compatible and/or guarantee success in finding a partner, or that (b) the service will be uninterrupted, secure or error free, (c) any defects or errors in the service will be corrected, (d) that any content or information you obtain on or through the service will be accurate, or (e) any suggestions or feedback will be incorporated.<br/><br/>
  
  Invisible Match takes no responsibility for any content that you or another user or third party posts, sends or receives through the service. Any material downloaded or otherwise obtained through the use of the service is accessed at your own discretion and risk.<br/><br/>
  
  Invisible Match disclaims and takes no responsibility for any conduct of you or any other user, on or off the service.</p>
  
  <h1>Advertising and External Links</h1>
 <p> The service may provide, or third parties may provide, links to other world wide web sites or resources. Because Invisible Match has no control over such sites and resources, you acknowledge and agree that Invisible Match is not responsible for the availability of such external sites or resources, is not responsible or liable for any content, advertising, products or other materials on or available from such sites or resources. You further acknowledge and agree that Invisible Match shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of, or reliance upon, any such content, goods or services available on or through any such site or resource. Please go through the terms of use and privacy policies of such sites carefully as you would be bound by those during such interactions.</p>
  
  <h1>Miscellaneous</h1>
  <p>- Invisible Match or Stuffenzy LLP can be acquired for a part or in whole by another company. This will be done without (written) notice.<br/><br/>
  
  - If you breach these terms of use and we take no action against you, we will still be entitled to use our rights and remedies in any other situation where you breach these terms of use.<br/><br/>
  
  - If any part of these terms of use is disallowed or found to be void by any court or regulator, the other provisions shall continue to apply. We will adjust these terms of use as necessary, in which case the invalid terms will be replaced by valid terms that differ the least from the concerned invalid terms.<br/><br/>
  
  - These terms of use are not intended to give rights to anyone except you and us.<br/><br/>
  
  - We reserve the right, at our discretion, to make changes to the terms of use and privacy policy at any time. The most updated version is available on our app and website. Please check the terms of use and privacy policy periodically for changes as all modifications are effective upon publication. Continued use of the Invisible Match app and website after revision indicates the user's acceptance of the terms of use.<br/><br/>
  
  In case you come across suspicious activity, immediately notify us at support@stuffenzy.com</p>
  
        </div>
    )
  }
}

export default Terms