import React, { Component } from 'react'

export class PrivacyPolicy extends Component {
  render() {
    return (
  <div
  
  style={{padding:40}}
  
  >
   <h1><b> Privacy Policy</b> </h1>

   <p>Invisible Match is strongly committed to your right to privacy. This section covers Invisible Match's privacy policy that highlights how we handle personally identifiable information that Invisible Match collects when you are on our Website or App. Please read this privacy policy carefully. All information covered by Invisible Match's privacy policy will continue to be protected under Invisible Match's privacy policy irrespective of any change in your membership status in the future. By using the Invisible Match Website or App, you accept all practices described in this privacy policy.</p>


<h1>Why do we collect personal information?</h1>


<p>In this Privacy Policy, your "Personal Data" means information or pieces of information that could allow you to be identified. We process your personal data for us to be able to carry out our contractual commitments towards you. We use this information to assist you in your search for a suitable partner in terms of age, gender, height, interests etc. In addition we process Personal Data to comply with legal obligations. We also process your Personal Data for allowing other users to view your profile on Invisible Match, conducting an analysis of your browsing pattern and history, and allowing our access, payment and storage business partners to process your Personal Data.</p>



<p>Sometimes we need your consent to process Personal Data, such as for sending you newsletters and promotions. Before you can become a user we also ask for your explicit consent so that we can:
</p>


<p>- Place tracking cookies</p>
<p>- Process your profile information</p>
<p>- Process data that you enter about your partner preferences</p>
<p>- Process photographs of you</p>
<p>- Process details of your work and interests</p>
<p>- Process your bio and other textual content added to your profile</p>
<p>- Process your geolocation</p>
<p>- Offer you services mentioned in this Privacy Policy that require an act of consent from you</p>
<p>You have the right to withdraw your consent at any time for processing your location, however,
this will not affect any of the prior processing of your Personal Data.</p>



<h1>Which personal information do we process?</h1>
<p>You will be asked to register before you can use Invisible Match. In the process, you will be asked
for your name, email ID etc. After registering, you can create your personal Invisible Match account
("Your Account"). In this respect, you are invited to provide us with your name, pictures of you, a
description of yourself, details about your interests, philosophy, work and basic information about
yourself (such as your age, height, religion, ethnicity, place of residence etc). We will process
these data.Furthermore, we will process your IP address, login information, customer service history, time
zone settings, device ID, browser language and - if you have given us explicit permission – your
geolocation.</p>

<h1>How do we use your personal data?</h1>
<p>We will only share your Personal Data with other users, and only what is necessary to assess if
another user could be interesting for you – which will be clarified below.</p>

<p>We will only disclose your Personal Data to third parties if there is a legal obligation to do so by a
statutory authority from the appropriate jurisdiction. We do not sell your Personal Data to third
parties.</p>


<p><b>Data on Your Account:</b> Your Account will be visible to other users. Your first name will be your
identity on our platform. The description of yourself, and basic information regarding yourself
(such as age, height, profession etc) will be accessible on Your Account. We advise you to be
careful with sharing information on Your Account with other users.</p>

<p><b>Your login information:</b> We will process and store your login information to verify your identity
to access Your Account.</p>

<p><b>Your email address:</b> We do not share your email address with third parties.</p>

<p><b>Your email address will be used to:</b> inform you of your membership status; notify you other
relevant activity regarding Your Account; engage you with the application through newsletters
and for troubleshooting.</p>

<p><b>SMS from Invisible Match:</b> By registering you give us permission to send you SMS notifications. If
you wish to not receive them, please mail us at support@stuffenzy.com.</p>
<p><b>Your geolocation:</b> We will share your current location to make it insightful for our users who
may live or work nearby. We will never share your zip code with other users of Invisible Match. You
can choose to share your geolocation, which is necessary to see which other users of
Invisible Match are nearby at any given moment. Your geolocation will be shared with other users
who have shared their geolocation. Please note that we never share accurate geolocations with
Invisible Match users – the accuracy is limited to 0.5 kilometers at the most.</p>

<p><b>Your date of birth:</b> Your date of birth is used to calculate your age, which will be visible on Your
Account.</p>

<p><b>Photos you upload:</b> While creating Your Account, it is necessary to upload pictures of yourself
and to select a display picture for your account. We may use an external face recognition tool
and other techniques to select the best pictures for Your Account. This selection may be based
on features like image quality, composition and authenticity. Images uploaded by you may be
modified by us to make them more presentable on the platform. New pictures uploaded by you
will be reviewed before they appear on Your Account. The pictures that you upload will be
displayed to other users. Your Avatar or other photos may also be included in email messages
from Invisible Match to other users, if Invisible Match considers you to be a good match with that user.
Your Account, including pictures might be displayed on the homepage of Invisible Match for public
viewing with your consent.</p>

<p><b>Your chat messages and other written content:</b> Your chat messages are strictly confidential
and is only processed in order to give you access to your chat-history. Please note that we do
use filter software and techniques that notifies us of chat messages with inappropriate content (e.g. derogatory or aggressive language, or unlawful use of our platform for advertisements or
link-building.) After such notification we reserve the right to erase the offensive content.</p>
<p>Your customer service history: If you contact our customer service team, we keep a record of
your correspondence with us, in order to optimize our service for you.</p>

<p><b>Non-personal data:</b> We may also process non-personal data whenever you use our website or
app. This data may include your browser type and version, the type of your computer or mobile
device and technical information about your means to connect to our Websites (such as your
operating system and platform and the utilized internet service providers).</p>

<p><b>Push notifications from Invisible Match:</b> If you have given us explicit permission, we will send you
push notifications via our App to inform you about your membership status and to notify you of
new messages you have received and other activity with regard to Your Account. We may also
send you push notifications with suggestions of people you might like and other promotional
material.</p>


<h1>Inviting friends</h1>
<p>If you refer a friend to Invisible Match, you will send an email message, Whatsapp message, SMS,
Messenger message to the contact details you submitted to us. We won't share these details
with anyone else. By choosing to invite your friend to join Invisible Match you confirm that your friend
is happy to receive the invitation. You also hereby agree to indemnify and hold Invisible Match
harmless from all claims arising out of your messages to any person through Invisible Match. You
must and will not use our invite friends feature to send spam.</p>

<h1>How do we protect your personal data?</h1>
<p>We try our best to ensure that your Personal Data is protected and secure from unauthorized or
unlawful access, alteration, disclosure, use or destruction. We encrypt our services using SSL,
the data is accessible only through a secret password and digital signatures, and our employees
only have access to your data on a need-to-know basis. Unfortunately, the transmission of
information via the internet is not always completely secure. Although we take all reasonable
measures, we cannot guarantee the security of the data you transmit to our Website or App; any
transmission is at your own risk.</p>

<h1>How long do we retain your personal data?</h1>
<p>Your Personal Data is retained no longer than strictly necessary or as long as allowed by law,
whichever is shorter. In the event that you have not logged in to your profile for five years, your
Personal Data may be erased from our records. Of course, you can always contact our support
team through your registered email address with a request to delete your data from our records.</p>

<h1>Which personal information do we process?</h1>
<p></p>


<h1>Changes to this privacy policy</h1>
<p>We are constantly looking for new ways to improve Invisible Match and we may unilaterally update
this Privacy Policy from time to time. When there is a significant change in this Privacy Policy,
you will see a pop up in the app which you will have to accept before you can continue to use
Invisible Match.</p>


<h1>Storage and transfer of your personal data</h1>
<p>Your personal data may be hosted by our storage partners in servers located in countries other
than your country of residence.
You can also view, edit or delete your Personal Data by logging in to your Invisible Match account.
To protect loss of data due to natural disasters and other such unforeseeable circumstances, we
store your details as backup for a safety retention window of upto three years following account
deletion. During this period, account information will be retained although the account will of
course not be visible on Invisible Match anymore.</p>
<p>When you delete your account, your personal data is deleted after this retention window. We only
retain your email address and phone number in order to be able to contact you with regard to
payment matters. For internal use, we may store generic, non-personal data regarding your
activity as a user (number of people invited, number of logins, term of your subscription, payment
method, etc).</p>

<p>Invisible Match shall not collect data from minors. Should a Member lie about his/her date of birth,
and particularly if he/she fraudulently claims to be over 18 years of age, the parents of the minor
in question should inform Invisible Match of this by sending an e-mail to support@stuffenzy.com,
requesting the data to be deleted. Invisible Match commits to deleting all data on the minor in
question as soon as possible.</p>
<p>When registering, the Member expressly consents to his/her data being transmitted to
Invisible Match service providers and subcontractors outside of the Member's country of residence,
to let Invisible Match run statistical studies, support users, and if need be, host data processed by
the Application.</p>

<p>Invisible Match guarantees that the transfers are made under conditions ensuring the confidentiality
and security of the data and providing an adequate level of protection in compliance with
regulations.</p>


<h1>We may disclose your personal information</h1>
<p>- in the event that we sell or buy any business or assets, in which case we may disclose your
personal information to the prospective seller or buyer of such business or assets</p>
<p>- to identify, contact or bring legal action against you if you are violating our terms of use or other
rules or if we believe you are causing injury or interference with the rights of users, the general
public or Invisible Match</p>
<p>- if we or substantially all of our assets are acquired by a third party, in which case personal
information held by us will be a part of the transferred assets</p>
<p>- if we are under a duty to disclose or share your personal information in order to comply with any
legal obligation, or in order to enforce or apply our terms and other agreements or to protect our
rights, property, or safety of our customers, or others</p>

<h1>Who are we?</h1>
<p>Invisible Match, the accompanying website and app are owned by Stuffenzy LLP, Stuffenzy LLP 46
LF BLDG 5B CTS 1A12B WI LINKING RD OPP OSHIWARA DEPOT GOREGAON WEST
MUMBAI-400104 MAHARASHTRA.</p>

<p>We reserve the right, at our discretion, to make changes to the terms of use and privacy policy at
any time. The most updated version is available on our App and Website at all times. Please
check the terms of use and privacy policy periodically for changes as all modifications are
effective upon publication. Continued use of the Invisible Match app and website after revision
indicates the user's acceptance of the terms of use and privacy policy.</p>

<p>In case you come across suspicious activity, immediately notify us at support@stuffenzy.com.</p>

  </div>
    )
  }
}

export default PrivacyPolicy