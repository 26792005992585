import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import { BrowserRouter,useHref, Routes, Route } from "react-router-dom";
import CancelPage from './CancelPage';
import PrivacyPolicy from './PrivacyPolicy';
import Terms from './Terms';
import Shipping from './Shipping';
import ResponsiveForm from './ResponsiveForm';

export default function Index() {
  return (
    <BrowserRouter>
      <Routes>
          <Route index element={<App />} />
          <Route path="/:transactionId/:email" element={<App />}  />
          <Route path="/cancel" element={<CancelPage />} />
          <Route path="/cancelpage.html" element={<CancelPage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/privacy.html" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/termsofser.html" element={<Terms />} />
          <Route path="/shipping" element={<Shipping />} />
          <Route path="/form" element={<ResponsiveForm />} />
          <Route path="*" element={<App />} />
          
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Index />);

